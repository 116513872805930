import React from "react";
import '/src/assets/css/Partners/partners-info-list.css'


const PartnersInfoList = ({partnerData}) => {
    return (
        <section id='partners-info-list'>

            <div className="container">
                <div className="row">

                    {
                        partnerData.map(partner => (
                            <div className="col-lg-4 col-md-6 col-12 mb-4">

                                {/*Partner Logo*/}
                                <div className="partner-logo w-100 text-center">
                                    <img src={partner.logo} className='img-fluid' alt={partner.logoAlt} loading='lazy'/>
                                </div>

                                <div className="partner-content">

                                    {/*Partner Description*/}
                                    <div className="partner-intro">
                                        <p>{partner.partnerDescription}</p>
                                    </div>


                                    {/*Partner Contact Details*/}
                                    <div className="mt-4">

                                        {partner.email ?
                                            <a href={`mailto:${partner.email}`} className="partner-contact-info">
                                                <i className='bx bx-envelope font-weight-bold'></i>
                                                <span className='ml-2'>{partner.email}</span>
                                            </a>
                                            :
                                            ''
                                        }

                                        {partner.phone ?
                                            <a href={`tel:${partner.phone}`} className="partner-contact-info">
                                                <i className='bx bx-phone font-weight-bold'></i>
                                                <span className='ml-2'>{partner.phone}</span>
                                            </a>
                                            :
                                            ''
                                        }

                                        {partner.website ?
                                            <a href={partner.website} target='_blank' className="partner-contact-info">
                                                <i className='bx bx-globe font-weight-bold'></i>
                                                <span className='ml-2'>{partner.website}</span>
                                            </a>
                                            :
                                            ''
                                        }


                                    </div>

                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>
    )
}

export default PartnersInfoList