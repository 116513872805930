import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PartnersInfoList from "../components/Common/PartnersInfoList";
import '/src/assets/css/Partners/partners-intro.css'
import {Link} from "gatsby"
import SEO from "../components/App/SEO";

import partnersHero from "../assets/images/partners/partners-hero.png"
import TwoCartLogoImg from "../assets/images/partners/2-cart-logo.png"
import ContentLogoImg from "../assets/images/partners/logo-content-online.png"
import TheConLogoImg from "../assets/images/partners/logo_thecon.png"
import XsureLogoImg from "../assets/images/partners/xsure_logo-small.png"
import prestaLogoImg from "../assets/images/partners/prestalabs-logo.png"
import modelLogoImg from "../assets/images/partners/modeltheme-logo.png"


const partnersList = [
    {
        logo: TheConLogoImg,
        logoAlt: 'Thecon',
        partnerDescription: 'Fie că ai nevoie de realizarea unui site de prezentare, magazin online sau cauți soluții pentru dezvoltarea unei aplicații mobile, ori un ERP/CRM performant sau orice altă aplicație custom, partenerii Thecon.ro îți pun la dispoziție soluții software realizate de developeri experimentați.',
        email: 'contact@thecon.ro',
        phone: '+40 756 192 121',
        website: 'https://thecon.ro',
    },
    {
        logo: TwoCartLogoImg,
        logoAlt: '2 Cart',
        partnerDescription: 'Platforma SaaS dezvoltată de partenerul 2Cart este soluția perfectă pentru a lansa în doar câteva clickuri propriul magazin online, beneficiind de toate avantajele tehnologilor PWA: încărcare rapidă a site-ului, îmbunătățire progresivă și multe altele.',
        email: 'contact@2cart.io',
        phone: '+40 740 47 00 48',
        website: 'https://2cart.io'
    },
    {
        logo: modelLogoImg,
        logoAlt: 'ModelTheme',
        partnerDescription: 'Toate proiectele de succes dezvoltate în WordPress încep alături de partenerul Modeltheme, autor Elite ENVATO, care încă din 2006 s-a specializat în realizarea de teme premium pentru diferite tipuri de website, potrivite unei palete largi de business-uri.',
        email: 'sales@modeltheme.com',
        phone: '+40 748 351 305',
        website: 'https://modeltheme.com',
    },
    {
        logo: prestaLogoImg,
        logoAlt: 'Presta Maniacs',
        partnerDescription: 'Magazinul tău online dezvoltat în PrestaShop va fi mai performant dacă vei folosi modulele dezvoltate de către partenerul Presta Maniacs. Descoperă ce module certificate PrestaShop îți sunt folositoare și nu ezitați să-i contactezi pentru mai multe informații.',
        email: 'contact@prestamaniacs.com',
        phone: '+40 757 383 844',
        website: 'https://prestamaniacs.com',
    },
    {
        logo: XsureLogoImg,
        logoAlt: 'XSure',
        partnerDescription: 'Începând cu dezvoltarea de produse software și până la transformări digitale complete, misiunea Xsure este să împrietenească tehnologia cu orice sector de business. Profesioniștii Xsure sunt gata să livreze soluții IT concepute la cele mai înalte standarde.',
        email: 'outsource@xsure.io',
        phone: '+44 1223 92 6506',
        website: 'https://xsure.io',
    },
    {
        logo: ContentLogoImg,
        logoAlt: 'Content Online',
        partnerDescription: 'Conteonline creează conținut pentru magazine online și promovează online business-uri din orice domeniu. Dacă ești în căutare de soluții complete de marketing digital, inclusiv creare de conținut strategic foto&video, apelează cu încredere la acești parteneri.',
        email: 'contact@contentonline.ro',
        phone: '+40 732 731 217',
        website: 'https://contentonline.ro',
    }

]

const Partners = () => {

    return (
        <Layout>
            {/*SEO*/}
            <SEO title="Află ce parteneri SiteBunker.ro te pot ajuta să scalezi în online."
                 description="Parteneriatele potrivite sunt esențiale atunci când vrei să-ți crești afacerea. Descoperă aici lista de parteneri SiteBunker.ro recomandați, alături de care poți rezolva orice provocare legată de magazinul tău online și nu numai."
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Hero Section*/}
            <section id='partners-intro'>
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-md-7 col-12 z-index-pos">
                            <h2 className='h2-title mb-4'>Parteneri</h2>
                            <h3 className='mb-2'>Descoperă lista de parteneri SiteBunker.ro alături de care și afacerea
                                ta online va scala.</h3>
                            <p className='mb-5'>Construim încredere și performanță pentru a susține creșterea în online
                                a oricărui tip de business. Alătură-te și tu partenerilor SiteBunker.ro și hai să
                                dovedim împreună cât de important este să apelezi la profesioniști.</p>
                            <Link className='view-more-button w-50' to='/contact'>Devino partener</Link>
                        </div>
                        <img src={partnersHero} alt="Partner Img" className='partners-hero-img' loading="lazy"/>
                    </div>
                </div>
            </section>

            {/*Partners List*/}
            <PartnersInfoList partnerData={partnersList}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    );
}

export default Partners;
